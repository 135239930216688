import Vue from 'vue'
import VueRouter from 'vue-router'
import OverviewView from '../views/OverviewView.vue'
import LoginView from "@/views/LoginView";
import FixedExpensesView from "@/views/FixedExpensesView";
import SignupView from "@/views/SignupView"

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'OverviewView',
    component: OverviewView
  },
  {
    path: '/login',
    name: 'loginView',
    component: LoginView
  },
  {
    path: '/fixedexpenses',
    name: 'fixedExpensesView',
    component: FixedExpensesView
  },
  {
    path: '/register',
    name: 'signupView',
    component: SignupView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
