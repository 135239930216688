import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

/*--------------------REGISTER BOOTSTRAP---------------------------------*/
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
/*-----------------------------------------------------------------------*/

import VueResource from 'vue-resource';

Vue.use(VueResource)

import locale from 'element-ui/lib/locale/lang/de'
import Element from 'element-ui'
import "./style.scss"

Vue.use(Element, {locale})

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

import Locales from "@/locales/de.json"


export {
    Locales
}

new Vue({
    router,
    vuetify: new Vuetify(),
    render: h => h(App)
}).$mount('#app')
