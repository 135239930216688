<template>
  <div>
    <v-btn 
        elevation="0"
        fab
        small
        @click="dialogDelete = true">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn text @click="deleteFixedExpense(); dialogDelete = false">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {fixedExpensesApi} from "@/backend";
import {Locales} from "@/main";
import {handleRequestError} from "@/helpers/error";
import { FixedExpense } from "@/finsheet_api/src";

export default {
  name: 'DeleteFixedExpense',
  props: {
    fixedExpense: {
      type: FixedExpense
    }
  },
  data() {
    return {
      locales: Locales,
      dialogDelete: false,
    }
  },
  mounted() {

  },
  watch: {},
  methods: {
    deleteFixedExpense() {
      fixedExpensesApi.deleteFixedExpense(this.fixedExpense.uuid, (error, data, response) => {
        if (error) {
          handleRequestError(response)
          return
        }
        this.$emit("success")
        this.$message.success(Locales.FixedExpenseDeletedSuccess)
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
