<template>
  <div>
    <BudgetInfo
        @updateWage="fetchWage"
        :wage="wage" :expenses="expenses" :expense-info="expenseInfos" :fixed-expenses="fixedExpenses" :requested-date="selectedDate"></BudgetInfo>

    <v-col class="text-center">
      <AddCategory v-if="selectedDate.getMonth() === new Date().getMonth()"
                   @updateExpenseInfo="fetchExpenseInfo"
      ></AddCategory>
    </v-col>

    <div class="spacing" v-for="expenseInfo in expenseInfos">
      <BudgetProgress :requested-date="selectedDate"
                      :expense-info="expenseInfo"
                      :expenses="expenses.filter(e=>e.categoryUuid === expenseInfo.category.uuid)"
                      @updateExpenseInfo="fetchExpenseInfo(); fetchMonthlyExpenses()"
                      @updateCategories="fetchExpenseInfo()"></BudgetProgress>
    </div>
    <div v-if="expenseInfos.length === 0">
      <v-col>
        <el-alert type="warning" :closable="false">

          {{ locales.SelectedMonthHasNoCategoriesString }}
        </el-alert>
      </v-col>
    </div>
  </div>
</template>

<script>

import {expensesApi, fixedExpensesApi, wageApi} from "@/backend";
import BudgetProgress from "@/charts/BudgetProgress";
import {Locales} from "@/main";
import BudgetInfo from "@/components/BudgetInformation";
import {handleRequestError} from "@/helpers/error";
import {MonthQuery} from "@/finsheet_api/src";
import AddCategory from "@/components/AddCategory";
import DatePicker from "@/components/DatePicker";

export default {
  name: "OverviewView",
  data() {
    return {
      locales: Locales,
      selectedDate: new Date(),
      expenseInfos: [],
      expenses: [],
      fixedExpenses: [],
      wage: '',
    }
  },
  mounted() {
    this.fetchExpenseInfo()
    this.fetchMonthlyExpenses()
    this.fetchWage()
    this.fetchFixedExpenses()
  },
  components: {
    DatePicker,
    AddCategory,
    BudgetProgress,
    BudgetInfo
  },
  mounted() {
    this.selectedDate = new Date(localStorage['selectedDate']);
    this.updateDate()
    window.addEventListener('selectedDateChanged', () => {
      this.selectedDate = new Date(localStorage['selectedDate']);
      this.updateDate()
    })
  },
  methods: {
    updateDate() {
      this.fetchExpenseInfo()
      this.fetchMonthlyExpenses()
      this.fetchWage()
      this.fetchFixedExpenses()
    },
    fetchMonthlyExpenses() {
      let monthQuery = new MonthQuery(this.selectedDate)
      expensesApi.getExpensesForMonth(monthQuery, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          this.expenses = []
          return
        }
        this.expenses = data
        this.expenses.forEach(e => {
          e.timestamp = new Date(Date.parse(e.timestamp)).toLocaleDateString("de-DE")
        })
      })
    },
    fetchWage() {
      let monthQuery = new MonthQuery(this.selectedDate)
      wageApi.getWage(monthQuery, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          return
        }
        this.wage = data.wage
      })
    },
    fetchExpenseInfo() {
      let monthQuery = new MonthQuery(this.selectedDate)
      expensesApi.getCategoryExpensesInfo(monthQuery, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          this.expenseInfos = []
          return
        }
        this.expenseInfos = data
      })
    },
    fetchFixedExpenses() {
      let monthQuery = new MonthQuery(this.selectedDate)
      fixedExpensesApi.getFixedExpensesByMonth(monthQuery, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          this.fixedExpenses = []
          return
        }
        this.fixedExpenses = data
      })
    }
  }
};
</script>

<style scoped>

</style>
