/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AddCategoryQuery from '../model/AddCategoryQuery';
import BaseError from '../model/BaseError';
import Category from '../model/Category';
import MonthQuery from '../model/MonthQuery';
import UpdateCategoryQuery from '../model/UpdateCategoryQuery';

/**
* Categories service.
* @module api/CategoriesApi
* @version 0.0.5
*/
export default class CategoriesApi {

    /**
    * Constructs a new CategoriesApi. 
    * @alias module:api/CategoriesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addCategory operation.
     * @callback module:api/CategoriesApi~addCategoryCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Adds a new entry to the category table of the logged in user
     * @param {module:model/AddCategoryQuery} addCategoryQuery 
     * @param {module:api/CategoriesApi~addCategoryCallback} callback The callback function, accepting three arguments: error, data, response
     */
    addCategory(addCategoryQuery, callback) {
      let postBody = addCategoryQuery;
      // verify the required parameter 'addCategoryQuery' is set
      if (addCategoryQuery === undefined || addCategoryQuery === null) {
        throw new Error("Missing the required parameter 'addCategoryQuery' when calling addCategory");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/categories/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteCategory operation.
     * @callback module:api/CategoriesApi~deleteCategoryCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes a category from the current user
     * @param {String} categoryUuid UUID of the category to delete
     * @param {module:api/CategoriesApi~deleteCategoryCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteCategory(categoryUuid, callback) {
      let postBody = null;
      // verify the required parameter 'categoryUuid' is set
      if (categoryUuid === undefined || categoryUuid === null) {
        throw new Error("Missing the required parameter 'categoryUuid' when calling deleteCategory");
      }

      let pathParams = {
        'category_uuid': categoryUuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/categories/delete/{category_uuid}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCategoriesByMonth operation.
     * @callback module:api/CategoriesApi~getCategoriesByMonthCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Category>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * returns a list of all categories for the logged in user
     * @param {module:model/MonthQuery} monthQuery 
     * @param {module:api/CategoriesApi~getCategoriesByMonthCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Category>}
     */
    getCategoriesByMonth(monthQuery, callback) {
      let postBody = monthQuery;
      // verify the required parameter 'monthQuery' is set
      if (monthQuery === undefined || monthQuery === null) {
        throw new Error("Missing the required parameter 'monthQuery' when calling getCategoriesByMonth");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [Category];
      return this.apiClient.callApi(
        '/categories/getAll', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateCategory operation.
     * @callback module:api/CategoriesApi~updateCategoryCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates an existing category of the logged in user
     * @param {module:model/UpdateCategoryQuery} updateCategoryQuery 
     * @param {module:api/CategoriesApi~updateCategoryCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateCategory(updateCategoryQuery, callback) {
      let postBody = updateCategoryQuery;
      // verify the required parameter 'updateCategoryQuery' is set
      if (updateCategoryQuery === undefined || updateCategoryQuery === null) {
        throw new Error("Missing the required parameter 'updateCategoryQuery' when calling updateCategory");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/categories/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
