<template>
  <div>
    <v-btn @click="overlay = true">
      <v-icon>mdi-calendar</v-icon>
      {{ locales.MonthNames[new Date(selectedDate).getMonth()] }}
    </v-btn>
    <v-overlay
        :z-index="100"
        :value="overlay"
    >
      <v-date-picker
          v-model="selectedDate"
          type="month"
          color="green lighten-1"
          header-color="primary"
          :max="maxDate"
          locale="de-de"
          @change="overlay = false; $emit('dateChanged', new Date(selectedDate))"
      ></v-date-picker>
    </v-overlay>
  </div>
</template>

<script>
import {Locales} from "@/main";

export default {
  name: "DatePicker",
  props: {
    buttonName: {
      type: String,
    }
  },
  data() {
    return {
      overlay: false,
      locales: Locales,
      selectedDate: new Date().toISOString().substr(0, 7),
    }
  },
  computed: {
    maxDate() {
      const today = new Date().toISOString().split("T")[0];
      return today;
    },
  },
}

</script>

<style scoped>

</style>