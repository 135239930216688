<template>
  <div>
    <v-card class="spacing" shaped elevation="5">

      <v-card-title>
        {{ locales.ExpensesOverviewHeader }}
      </v-card-title>
      <v-card-subtitle class="text-left">
        {{ locales.MonthNames[requestedDate.getMonth()] }}
      </v-card-subtitle>
      <v-divider></v-divider>
      <Wage @updateWage="$emit('updateWage')"
            :requested-date="requestedDate"
            :wage="wage"></Wage>
      <v-card-text>
        <div class="subheading font-weight-light grey--text">
          {{ locales.Budget }}: {{ (Math.max(sumOfAllExpenses, 0)).toFixed(2) }}{{ locales.CurrencySymbol }} /
          {{ totalBudget.toFixed(2) }}{{ locales.CurrencySymbol }}
        </div>
        <el-progress type="line"
                     :stroke-width="10"
                     :percentage="percentage"
                     :color="colorMethod"
        >
        </el-progress>
        <div class="subheading font-weight-light grey--text">
          Alle Fixkosten: <span style="color:purple">{{ sumOfAllFixedExpenses.toFixed(2) }}{{ locales.CurrencySymbol }}</span>
        </div>
        <div class="subheading font-weight-light grey--text">
          Ausgaben aller Kategorien: <span style="color:red">{{ sumOfAllDynamicExpenses.toFixed(2) }}{{ locales.CurrencySymbol }}</span>
        </div>
        <div class="subheading font-weight-light grey--text">
          Gesamtausgaben: <span style="color:red">{{ sumOfAllExpenses.toFixed(2) }}{{ locales.CurrencySymbol }}</span>
        </div>
        <div class="subheading font-weight-light grey--text">
          Verbleibendes Gehalt: <span :style="amountOfWageLeft > 0 ? 'color:green' : 'color:red'">{{ amountOfWageLeft.toFixed(2) }}{{ locales.CurrencySymbol }}</span>
        </div>
        <v-alert
            dense
            outlined
            type="error"
            :value="sumOfAllExpenses > totalBudget"
        >
          {{
            locales.YouSpend01MoreThanExpected.replace("{0}", Math.abs(totalBudget - sumOfAllExpenses).toFixed(2)).replace("{1}", locales.CurrencySymbol)
          }}
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {Locales} from "@/main";
import {CategoryExpenseInfo, Expense, FixedExpense} from "@/finsheet_api/src";
import {percentageToColorRange} from "@/helpers/colorUtil";
import Wage from "@/components/Wage";

export default {
  name: "BudgetInformation",
  components: {Wage},
  props: {
    expenseInfo: {
      type: Array(CategoryExpenseInfo),
      default() {
        return []
      },
    },
    fixedExpenses: {
      type: Array(FixedExpense),
      default() {
        return []
      }
    },
    requestedDate: {
      type: Date
    },
    expenses: {
      type: Array[Expense],
      default() {
        return []
      },
    },
    wage: {},
  },
  data() {
    return {
      locales: Locales,
    }
  },
  computed: {
    totalBudget() {
      let result = 0
      this.expenseInfo.forEach(e => {
        result += e.budget
      })
      return result + this.sumOfAllFixedExpenses
    },
    sumOfAllFixedExpenses() {
      let result = 0
      this.fixedExpenses.forEach(fe => {
        fe.expenses.forEach(e => {
          if (e.isExpense) {
            result += e.value
          }
          else {
            result -= e.value
          }
        })
      })
      return result
    },
    sumOfAllDynamicExpenses() {
      let result = 0
      this.expenseInfo.forEach(e => {
        result += e.totalExpenses
        result -= e.totalRevenue
      })
      return result
    },
    sumOfAllExpenses() {
      return this.sumOfAllDynamicExpenses + this.sumOfAllFixedExpenses
    },
    amountOfWageLeft() {
      return this.wage - this.sumOfAllExpenses
    },
    percentage() {
      return Math.round((Math.max(0, this.sumOfAllExpenses) / this.totalBudget) * 100)
    },
  },
  methods: {
    colorMethod(percentage) {
      return percentageToColorRange(percentage)
    },
  },
}
</script>

<style scoped>

</style>