<template>
  <div>
    <v-btn
        elevation="0"
        small
        @click="dialog=true"
    >
      <v-icon dark>
        mdi-pencil
      </v-icon>
    </v-btn>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ expenseInfo.category.name }} {{ locales.Edit }}
        </v-card-title>
        <v-form ref="budgetForm" v-model="valid">
          <v-container>
            <v-text-field
                v-model="categoryName"
                :rules="stringRules"
                :label="locales.Name"
                required
                type="string"
            ></v-text-field>
            <v-text-field
                v-model="budget"
                :rules="budgetRules"
                :label="locales.Budget"
                required
                type="number"
            ></v-text-field>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
          >
            {{ locales.Cancel }}
          </v-btn>
          <v-btn
              color="green darken-1"
              number
              @click="validate()"
          >
            {{ locales.Save }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {Category, CategoryExpenseInfo, MonthQuery, UpdateCategoryQuery} from "@/finsheet_api/src";
import {Locales} from "@/main";
import {categoriesApi} from "@/backend";
import {handleRequestError} from "@/helpers/error";

export default {
  name: 'UpdateCategory',
  data() {
    return {
      dialog: false,
      valid: false,
      budget: 0,
      categoryName: "",
      budgetRules: [
        v => !!v || Locales.NoBudgetSetError,
        v => v > 0 || Locales.BudgetMustBePositiveError,
      ],
      stringRules: [
        v => !!v || Locales.NoStringEnteredError,
        v => typeof v === 'string' || Locales.InvalidStringEnteredError,
      ],
      locales: Locales,
    }
  },
  props: {
    expenseInfo: {
      type: CategoryExpenseInfo
    },
  },
  watch: {

  },
  created: function() {
    this.readValuesFromExpenseInfo()
  },
  watch: {
    expenseInfo(newValue, oldValue) {
      this.readValuesFromExpenseInfo()
    }
  },
  methods: {
    readValuesFromExpenseInfo() {
      this.budget = this.expenseInfo.category.budget
      this.categoryName = this.expenseInfo.category.name
    },
    validate() {
      let tmp = this.budget
      if (this.$refs.budgetForm.validate()) 
      {
        this.dialog = false
        if (this.budget !== this.expenseInfo.budget || this.categoryName !== this.expenseInfo.category.name)
        {
          let categoryQuery = new UpdateCategoryQuery()
          categoryQuery.uuid = this.expenseInfo.category.uuid
          categoryQuery.budget = parseFloat(tmp)
          categoryQuery.name = this.categoryName
          categoryQuery.monthQuery = new MonthQuery(this.expenseInfo.date)
          categoriesApi.updateCategory(categoryQuery, (error, data, response) => {
            if (error) {
              if (!handleRequestError(response))
              {
                let errorJson = JSON.parse(response.text)
                this.$message.error(errorJson.message)
              }
              return
            }
            this.$message.success(Locales.CategoryUpdatedSuccess)
            this.$emit("updateCategories")
          })
        }
      }
    },
    reset() {
      this.$refs.budgetForm.reset()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
