<template>
  <div>
    <v-btn
        elevation="0"
        @click="dialog = true"
    >
      <v-icon>mdi-plus</v-icon>
      {{ locales.AddCategory }}
    </v-btn>

    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ locales.AddNewCategoryHeader }}
        </v-card-title>
        <v-card-text>
          <v-form ref="categoryForm">
            <v-row>
              <v-col cols="7">
                <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    :label="locales.Category"
                    required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="budget"
                    :rules="budgetRules"
                    :label="locales.Budget"
                    type="number"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions style="margin-left: 10px; margin-bottom: 10px">
          <v-btn
              @click="verifyForm"
          >
            <v-icon>mdi-plus</v-icon>
            {{ locales.AddEntry }}
          </v-btn>
          <v-btn
              class="ma-1"
              @click="dialog=false; name=''; budget=''"
          >
            <v-icon>mdi-cancel</v-icon>
            {{ locales.Cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {AddCategoryQuery} from "@/finsheet_api/src";
import {categoriesApi} from "@/backend";
import {Locales} from "@/main";
import {handleRequestError} from "@/helpers/error";

export default {
  name: 'AddCategory',
  data() {
    return {
      locales: Locales,
      name: '',
      budget: '',
      dialog: false,
      budgetRules: [
        v => !!v || Locales.NoBudgetSetError,
        v => v > 0 || Locales.BudgetMustBePositiveError,
      ],
      nameRules: [
        v => !!v || Locales.NameRequiredError,
      ],
    }
  },
  watch: {},
  methods: {
    verifyForm() {
      if (this.$refs.categoryForm.validate()) {
        this.addNewEntry()
        this.name = ''
        this.budget = ''
        this.dialog = false
      }
    },
    addNewEntry() {
      let categoryQuery = new AddCategoryQuery()
      categoryQuery.name = this.name
      categoryQuery.budget = parseFloat(this.budget)
      categoriesApi.addCategory(categoryQuery, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          return
        }
        this.$message.success(Locales.CategoryAddedSuccess + ": '" + categoryQuery.name + "'")
        this.$emit("updateExpenseInfo")
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
