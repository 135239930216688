<template>
  <v-app>
    <v-card class="overflow-hidden">
      <v-app-bar
          absolute
          color="#fcb69f"
          dark
          small
          src="https://picsum.photos/1920/1080?random"
          scroll-target="#scrolling-techniques-2"
      >
        <template v-slot:img="{ props }">
          <v-img
              v-bind="props"
              gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
          ></v-img>
        </template>
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
        <v-app-bar-title>{{ locales.Finsheet }}</v-app-bar-title>
      </v-app-bar>
      <br/>
      <br/>
      <br/>
      <DatePicker @dateChanged="updateDate"></DatePicker>
      <v-sheet
          id="scrolling-techniques-3"
          class="overflow-y-auto"
          height="90vh">
        <router-view/>
        <br/>
      </v-sheet>

    </v-card>
      <v-bottom-navigation app grow v-model="value">
          <v-btn v-for="item in items" :to="item.link">
            {{ item.title }}
            <v-icon>{{ item.icon }}</v-icon>
          </v-btn>
      </v-bottom-navigation>
  </v-app>
</template>

<script>

import {Locales} from "@/main";
import DatePicker from "@/components/DatePicker";

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      locales: Locales,
      selectedMonth: new Date().getMonth(),
      value: Locales.Overview,
      items: [
        {title: Locales.Overview, icon: 'mdi-view-dashboard', link: '/'},
        {title: Locales.User, icon: 'mdi-cash', link: '/fixedexpenses'},
      ],
    }
  },
  created() {
    this.updateDate(new Date())
  },
  methods: {
    updateDate(newDate) {
      localStorage.setItem("selectedDate", newDate);
      window.dispatchEvent(new Event("selectedDateChanged"));
    },
  },
}
</script>
