export function HSVtoRGB(h, s, v) {
    var r, g, b, i, f, p, q, t;
    if (arguments.length === 1) {
        s = h.s
        v = h.v
        h = h.h
    }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
        case 0:
            r = v
            g = t
            b = p
            break;
        case 1:
            r = q
            g = v
            b = p
            break;
        case 2:
            r = p
            g = v
            b = t
            break;
        case 3:
            r = p
            g = q
            b = v
            break;
        case 4:
            r = t
            g = p
            b = v
            break;
        case 5:
            r = v
            g = p
            b = q
            break;
    }
    return {
        r: Math.round(r * 255),
        g: Math.round(g * 255),
        b: Math.round(b * 255)
    }
}

export function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
    return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function percentageToColorRange(percentage) {
    const h = (1 - (Math.max(0, Math.min(100, percentage)) / 100)) * 0.35; // Hue (note 0.4 = Green)
    const s = 0.9; // Saturation
    const b = 0.9; // Brightness

    let rgb = HSVtoRGB(h, s, b)
    return rgbToHex(rgb.r, rgb.g, rgb.b)
}