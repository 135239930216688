<template>
  <div>
    <AddFixedExpense v-if="selectedDate.getMonth() === new Date().getMonth()" @success="fetchData()"></AddFixedExpense>
    <v-data-table
        :headers="headers"
        :items="tableData"
        :items-per-page="itemsPerPage"
        mobile-breakpoint="0"
        hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <p class="font-weight-black">
          {{ item.name }}
          <v-chip :color="getChipColor(expenseSum(item))" dark>
            {{ Math.abs(expenseSum(item)).toFixed(2) }}
          </v-chip>
        </p>
        <p>Zusammensetzung:</p>
        <p v-for="expense in item.expenses">     
            {{ expense.description }}
            <span :style="getStyleColor(expense.isExpense)">{{ expense.value.toFixed(2) }}{{ locales.CurrencySymbol }}</span>
        </p>
      </template>
      <template v-if="selectedDate.getMonth() === new Date().getMonth()" v-slot:item.actions="{ item }">
        <DeleteFixedExpense @success="fetchData()" :fixed-expense="item"></DeleteFixedExpense>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import {Locales} from "@/main";
import DeleteFixedExpense from "@/components/DeleteFixedExpense.vue";
import AddFixedExpense from "./AddFixedExpense.vue";
import { fixedExpensesApi } from "@/backend";
import { handleRequestError } from "@/helpers/error";
import { MonthQuery } from "@/finsheet_api/src";

export default {
  name: 'ExpensesTable',
  components: {
    DeleteFixedExpense,
    AddFixedExpense
  },
  data() {
    return {
      locales: Locales,
      itemsPerPage: -1,
      headers: [
        {value: 'name', text: Locales.Description},
        { text: '', value: 'actions', sortable: false },
      ],
      dialogDelete: false,
      fixedExpenses: [],
    }
  },
  props: {
    selectedDate: {
      type: Date
    },
  },
  watch: {
    selectedDate: function(old, newval) {
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    tableData() {
      return this.fixedExpenses
    },
  },
  methods: {
    expenseSum(fixedExpense) {
      let result = 0
      fixedExpense.expenses.forEach(e => {
        if (e.isExpense) {
          result += e.value
        }
        else {
          result -= e.value
        }
      })
      return result
    },
    getStyleColor(isExpense) {
      return isExpense === true ? "color:red" : "color:green";
    },
    getChipColor(value) {
      return value > 0 ? "red" : "green";
    },
    fetchData() {
      let monthQuery = new MonthQuery(this.selectedDate)
      fixedExpensesApi.getFixedExpensesByMonth(monthQuery, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          this.fixedExpenses = []
          return
        }
        this.fixedExpenses = data
      })
    }
  }
}
</script>
