<template>
  <div>
    <v-btn
        elevation="0"
        small
        @click="dialog = true"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>

    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ locales.DeleteCategory0Header.replace('{0}', expenseInfo.category.name) }}
        </v-card-title>
        <v-card-text>

        </v-card-text>
        <v-card-actions style="margin-left: 10px; margin-bottom: 10px">
          <v-btn
              @click="deleteCategory"
          >
            <v-icon>mdi-delete</v-icon>
            {{ locales.Delete }}
          </v-btn>
          <v-btn
              class="ma-1"
              @click="dialog=false"
          >
            <v-icon>mdi-cancel</v-icon>
            {{ locales.Cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {CategoryExpenseInfo} from "@/finsheet_api/src";
import {categoriesApi} from "@/backend";
import {Locales} from "@/main";
import {handleRequestError} from "@/helpers/error";

export default {
  name: 'DeleteCategory',
  data() {
    return {
      locales: Locales,
      dialog: false,
    }
  },
  props: {
    expenseInfo: {
      type: CategoryExpenseInfo
    }
  },
  watch: {},
  methods: {
    deleteCategory() {
      this.dialog = false
      if (this.expenseInfo.totalExpenses > 0 || this.expenseInfo.totalRevenue > 0) {
        this.$message.error(Locales.CategoryCantBeDeletedBecauseExpensesArePresentError)
        return
      }
      categoriesApi.deleteCategory(this.expenseInfo.category.uuid, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          return
        }
        this.$message.success(Locales.CategoryDeletedSuccess)
        this.$emit("updateExpenseInfo")
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
