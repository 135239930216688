/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BaseError from '../model/BaseError';
import FixedExpense from '../model/FixedExpense';
import MonthQuery from '../model/MonthQuery';

/**
* Fixedexpenses service.
* @module api/FixedexpensesApi
* @version 0.0.5
*/
export default class FixedexpensesApi {

    /**
    * Constructs a new FixedexpensesApi. 
    * @alias module:api/FixedexpensesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addFixedExpense operation.
     * @callback module:api/FixedexpensesApi~addFixedExpenseCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Adds a new fixed expense entry for the logged in user
     * @param {module:model/FixedExpense} fixedExpense 
     * @param {module:api/FixedexpensesApi~addFixedExpenseCallback} callback The callback function, accepting three arguments: error, data, response
     */
    addFixedExpense(fixedExpense, callback) {
      let postBody = fixedExpense;
      // verify the required parameter 'fixedExpense' is set
      if (fixedExpense === undefined || fixedExpense === null) {
        throw new Error("Missing the required parameter 'fixedExpense' when calling addFixedExpense");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/fixedexpenses/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteFixedExpense operation.
     * @callback module:api/FixedexpensesApi~deleteFixedExpenseCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes a fixed expense from the current user
     * @param {String} expenseUuid UUID of the fixed expense to delete
     * @param {module:api/FixedexpensesApi~deleteFixedExpenseCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteFixedExpense(expenseUuid, callback) {
      let postBody = null;
      // verify the required parameter 'expenseUuid' is set
      if (expenseUuid === undefined || expenseUuid === null) {
        throw new Error("Missing the required parameter 'expenseUuid' when calling deleteFixedExpense");
      }

      let pathParams = {
        'expense_uuid': expenseUuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/fixedexpenses/delete/{expense_uuid}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getFixedExpensesByMonth operation.
     * @callback module:api/FixedexpensesApi~getFixedExpensesByMonthCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/FixedExpense>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * returns a list of all categories for the logged in user
     * @param {module:model/MonthQuery} monthQuery 
     * @param {module:api/FixedexpensesApi~getFixedExpensesByMonthCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/FixedExpense>}
     */
    getFixedExpensesByMonth(monthQuery, callback) {
      let postBody = monthQuery;
      // verify the required parameter 'monthQuery' is set
      if (monthQuery === undefined || monthQuery === null) {
        throw new Error("Missing the required parameter 'monthQuery' when calling getFixedExpensesByMonth");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [FixedExpense];
      return this.apiClient.callApi(
        '/fixedexpenses/getAll', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateFixedExpense operation.
     * @callback module:api/FixedexpensesApi~updateFixedExpenseCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Updates an existing fixed expense from the current user
     * @param {module:model/FixedExpense} fixedExpense 
     * @param {module:api/FixedexpensesApi~updateFixedExpenseCallback} callback The callback function, accepting three arguments: error, data, response
     */
    updateFixedExpense(fixedExpense, callback) {
      let postBody = fixedExpense;
      // verify the required parameter 'fixedExpense' is set
      if (fixedExpense === undefined || fixedExpense === null) {
        throw new Error("Missing the required parameter 'fixedExpense' when calling updateFixedExpense");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/fixedexpenses/update', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
