

let finsheetApi = require('./finsheet_api/src/index')
let finsheetApiClient = new finsheetApi.ApiClient()

finsheetApiClient.basePath = '/finsheetapi' // MUST NOT END WITH SLASH

// USE this if you are using a Chrome based Browser
finsheetApiClient.defaultHeaders['User-Agent'] = null

let expensesApi = new finsheetApi.ExpensesApi(finsheetApiClient)
let fixedExpensesApi = new finsheetApi.FixedexpensesApi(finsheetApiClient)
let categoriesApi = new finsheetApi.CategoriesApi(finsheetApiClient)
let usersApi = new finsheetApi.UsersApi(finsheetApiClient)
let wageApi = new finsheetApi.WageApi(finsheetApiClient)



export function setUpApis(username, password) {
    finsheetApiClient.authentications['BasicAuth'].username = username;
    finsheetApiClient.authentications['BasicAuth'].password = password;
}

export {
    finsheetApiClient,
    expensesApi,
    fixedExpensesApi,
    categoriesApi,
    usersApi,
    wageApi
}
