/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Expense model module.
 * @module model/Expense
 * @version 0.0.5
 */
class Expense {
    /**
     * Constructs a new <code>Expense</code>.
     * @alias module:model/Expense
     */
    constructor() { 
        
        Expense.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Expense</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Expense} obj Optional instance to populate.
     * @return {module:model/Expense} The populated <code>Expense</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Expense();

            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('categoryUuid')) {
                obj['categoryUuid'] = ApiClient.convertToType(data['categoryUuid'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'Number');
            }
            if (data.hasOwnProperty('isExpense')) {
                obj['isExpense'] = ApiClient.convertToType(data['isExpense'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} uuid
 */
Expense.prototype['uuid'] = undefined;

/**
 * @member {Date} timestamp
 */
Expense.prototype['timestamp'] = undefined;

/**
 * @member {String} categoryUuid
 */
Expense.prototype['categoryUuid'] = undefined;

/**
 * @member {String} description
 */
Expense.prototype['description'] = undefined;

/**
 * @member {Number} value
 */
Expense.prototype['value'] = undefined;

/**
 * @member {Boolean} isExpense
 */
Expense.prototype['isExpense'] = undefined;






export default Expense;

