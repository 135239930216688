<template>
    <div>
        <v-card>
            <v-card-title class="text-h5">
            {{ locales.Register }}
            </v-card-title>
            <v-form ref="signupForm" v-model="formValid">
                <v-container>
                <v-text-field
                    v-model="userName"
                    :rules="usernameRules"
                    :label="locales.UserName"
                    required
                    type="string"
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :label="locales.Password"
                    required
                    type="string"
                ></v-text-field>
                <v-text-field
                    v-model="repeatedPassword"
                    :rules="repeatPasswordRules"
                    :label="locales.RepeatPassword"
                    required
                    type="string"
                ></v-text-field>
                </v-container>
            </v-form>
            <v-card-actions>
                <v-row justify="center">
                    <v-btn
                        color="green darken-1"
                        text
                        @click="validate()"
                    >
                        {{ locales.Register }}
                    </v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
        <p>{{ userName }}</p>
    <p>{{ password }}</p>
    </div>
    </template>
    
    
<script>
import {Locales} from "@/main";
import {RegisterUserQuery} from "@/finsheet_api/src";
import {usersApi} from "@/backend";
import router from "@/router";
import {handleRequestError} from "@/helpers/error";

export default{

    data() {
        return {
            locales: Locales,
            formValid: '',
            userName: '',
            password: '',
            repeatedPassword: '',
            usernameRules: [
                v => !!v || Locales.NoStringEnteredError,
                v => typeof v === 'string' || Locales.InvalidStringEnteredError,
            ],
            passwordRules: [
                v => !!v || Locales.NoStringEnteredError,
                v => typeof v === 'string' || Locales.InvalidStringEnteredError,
            ],
            repeatPasswordRules: [
                v => !!v || Locales.NoStringEnteredError,
                v => typeof v === 'string' || Locales.InvalidStringEnteredError,
                v => this.password === this.repeatedPassword || "Die Passwörter stimmen nicht überein!",
            ],
        }
    },
    methods: {
        validate() {
            if (this.$refs.signupForm.validate()) 
            {
                let registerQuery = new RegisterUserQuery(this.userName, this.password)
                usersApi.register(registerQuery, (error, data, response) => {
                if (error) {
                    if (!handleRequestError(response))
                    {
                      let errorJson = JSON.parse(response.text)
                      this.$message.error(errorJson.message)
                    }
                    return
                }
                this.$message.success("Erfolgreich registriert")
                router.push("/")
            })
            }
        },
    }
    
}
</script>


<style scoped> 

form {
    max-width: 600px;
    margin: 30px auto;
    background: #fff;
    text-align: left;
    padding: 20px;
    border-radius: 10px;
}

button {
    background: rgb(7, 24, 7);
    border: 0;
    padding: 10px 20px;
    color: white;
    border-radius: 20px;
}

</style>
