/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FixedExpenseItem from './FixedExpenseItem';

/**
 * The FixedExpense model module.
 * @module model/FixedExpense
 * @version 0.0.5
 */
class FixedExpense {
    /**
     * Constructs a new <code>FixedExpense</code>.
     * @alias module:model/FixedExpense
     */
    constructor() { 
        
        FixedExpense.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>FixedExpense</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/FixedExpense} obj Optional instance to populate.
     * @return {module:model/FixedExpense} The populated <code>FixedExpense</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new FixedExpense();

            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('expenses')) {
                obj['expenses'] = ApiClient.convertToType(data['expenses'], [FixedExpenseItem]);
            }
        }
        return obj;
    }


}

/**
 * @member {String} uuid
 */
FixedExpense.prototype['uuid'] = undefined;

/**
 * @member {String} name
 */
FixedExpense.prototype['name'] = undefined;

/**
 * @member {Array.<module:model/FixedExpenseItem>} expenses
 */
FixedExpense.prototype['expenses'] = undefined;






export default FixedExpense;

