<template>
  <div>
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ headerText }}
        </v-card-title>
        <v-card-text>
          <v-form ref="expenseForm">
            <v-row>
              <v-col>
                <v-text-field
                    v-model="expenseDescription"
                    :rules="descriptionRules"
                    :counter="25"
                    :label="locales.Description"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    v-model="expenseValue"
                    :rules="expenseRules"
                    :label="locales.CurrencyAmountPlaceholder"
                    required
                    type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-col>
              <v-radio-group
                  v-model="expenseType"
                  row
              >
                <v-radio
                    :label="locales.Expense"
                    color="red"
                    :value="locales.Expense"
                ></v-radio>
                <v-radio
                    :label="locales.Revenue"
                    color="green lighten-2"
                    :value="locales.Revenue"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions style="margin-left: 10px; margin-bottom: 10px">
          <v-btn
              @click="verifyForm"
          >
            <v-icon>mdi-plus</v-icon>
            {{ locales.AddEntry }}
          </v-btn>
          <v-btn
              class="ma-1"
              @click="$emit('discard'); expenseDescription=''; expenseValue=''"
          >
            <v-icon>mdi-cancel</v-icon>
            {{ locales.Cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {Locales} from "@/main";

export default {
  name: 'AddNewExpenseDialog',
  props: {
    headerText: {
      type: String,
      default() {
        return ""
      },
    },
    showDialog: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      expenseValue: '',
      expenseDescription: '',
      expenseType: Locales.Expense,
      locales: Locales,
      categories: [],
      expenseRules: [
        v => !!v || Locales.NoValueSelectedError,
        v => v > 0 || Locales.BudgetMustBePositiveError,
      ],
      descriptionRules: [
        v => !!v || Locales.NoStringEnteredError,
      ],
    }
  },
  methods: {
    verifyForm() {
      if (this.$refs.expenseForm.validate()) {
        this.$emit('success', this.expenseDescription, this.expenseValue, this.expenseType === Locales.Expense)
        this.expenseValue = ''
        this.expenseDescription = ''
        this.expense = Locales.Expense
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
