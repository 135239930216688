/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BaseError from '../model/BaseError';
import EditWageQuery from '../model/EditWageQuery';
import MonthQuery from '../model/MonthQuery';
import Wage from '../model/Wage';

/**
* Wage service.
* @module api/WageApi
* @version 0.0.5
*/
export default class WageApi {

    /**
    * Constructs a new WageApi. 
    * @alias module:api/WageApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the editWageForCurrentMonth operation.
     * @callback module:api/WageApi~editWageForCurrentMonthCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Edits the wage for the current month
     * @param {module:model/EditWageQuery} editWageQuery 
     * @param {module:api/WageApi~editWageForCurrentMonthCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    editWageForCurrentMonth(editWageQuery, callback) {
      let postBody = editWageQuery;
      // verify the required parameter 'editWageQuery' is set
      if (editWageQuery === undefined || editWageQuery === null) {
        throw new Error("Missing the required parameter 'editWageQuery' when calling editWageForCurrentMonth");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/wage/editCurrent', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getWage operation.
     * @callback module:api/WageApi~getWageCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Wage} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns the wage of the selected month
     * @param {module:model/MonthQuery} monthQuery 
     * @param {module:api/WageApi~getWageCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Wage}
     */
    getWage(monthQuery, callback) {
      let postBody = monthQuery;
      // verify the required parameter 'monthQuery' is set
      if (monthQuery === undefined || monthQuery === null) {
        throw new Error("Missing the required parameter 'monthQuery' when calling getWage");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Wage;
      return this.apiClient.callApi(
        '/wage/get', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
