<template>
  <div>
    <v-btn
        elevation="0"
        small
        @click="showAddFixedExpenseDialog = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <EditFixedExpenseDialog 
      :show-dialog="showAddFixedExpenseDialog"
      @success="addFixedExpense"
      @discard="showAddFixedExpenseDialog = false"></EditFixedExpenseDialog>
  </div>
</template>

<script>

import {fixedExpensesApi} from "@/backend";
import {FixedExpense} from "@/finsheet_api/src";
import {handleRequestError} from "@/helpers/error";
import { Locales } from "@/main";
import EditFixedExpenseDialog from "@/dialogs/EditFixedExpenseDialog"

export default {
  name: 'AddFixedExpense',
  data() {
    return {
      locales: Locales,
      showAddFixedExpenseDialog: false,
    }
  },
  components: {
    EditFixedExpenseDialog
  },
  methods: {
    addFixedExpense(name, expenses) {
      let expenseQuery = new FixedExpense()
      expenseQuery.expenses = []
      expenseQuery.name = name
      expenses.forEach(e => {
        expenseQuery.expenses.push(e)
      })
      fixedExpensesApi.addFixedExpense(expenseQuery, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          return
        }
        this.showAddFixedExpenseDialog = false;
        this.$emit('success')
        this.$message.success(Locales.ExpenseAddedSuccess)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
