<template>
  <div>
    <v-row>
      <v-col>
        <v-banner
            single-line
            rounded
        >
          <v-icon
              slot="icon"
              color="green"
              size="25"
          >
            mdi-cash
          </v-icon>
          {{ locales.Wage }}: {{ wageToDisplay }}{{ locales.CurrencySymbol }}

          <template v-slot:actions>
            <v-btn
                v-if="requestedDate.getMonth() === new Date().getMonth()"
                fab
                x-small
                elevation="0"
                class="ma-1"
                @click="dialog=true"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-banner>
      </v-col>
    </v-row>
    <v-dialog
        v-model="dialog"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ locales.EditWageFor0Header.replace("{0}", locales.MonthNames[requestedDate.getMonth()]) }}
        </v-card-title>
        <v-card-text>
          <v-form ref="wageForm">
            <v-text-field
                v-model="wageToEdit"
                :rules="wageRules"
                :label="locales.CurrencyAmountPlaceholder"
                required
                type="number"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions style="margin-left: 10px; margin-bottom: 10px">
          <v-btn
              @click="verifyForm"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
              class="ma-1"
              @click="dialog=false; wageToEdit=''"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {EditWageQuery} from "@/finsheet_api/src";
import {wageApi} from "@/backend";
import {Locales} from "@/main";
import {handleRequestError} from "@/helpers/error";

export default {
  name: 'Wage',
  data() {
    return {
      locales: Locales,
      dialog: false,
      wageToEdit: 0,
      wageToDisplay: '',
      wageRules: [
        v => !!v || Locales.NoBudgetSetError,
        v => v > 0 || Locales.BudgetMustBePositiveError,
      ],
    }
  },
  props: {
    requestedDate: {
      type: Date()
    },
    wage: {},
  },
  watch: {
    wage(newVal) {
      this.wageToDisplay = newVal
    }
  },
  methods: {
    verifyForm() {
      if (this.$refs.wageForm.validate()) {
        this.editEntry()
        this.wage = ''
      }
    },
    editEntry() {
      let editWageQuery = new EditWageQuery()
      editWageQuery.wage = parseFloat(this.wageToEdit)
      wageApi.editWageForCurrentMonth(editWageQuery, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          return
        }
        this.wageToDisplay = this.wageToEdit
        this.dialog = false
        this.$emit("updateWage")
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
