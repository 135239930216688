import router from "@/router";

export function pushLoginPage() {
    router.push("/login")
}

export function handleRequestError(response) {
    if (response.statusCode === 401) {
        pushLoginPage()
        return true
    }
    return false
}