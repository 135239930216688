/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The MonthQuery model module.
 * @module model/MonthQuery
 * @version 0.0.5
 */
class MonthQuery {
    /**
     * Constructs a new <code>MonthQuery</code>.
     * This query is used to retrieve results only for the month of the timestamp
     * @alias module:model/MonthQuery
     * @param timestamp {Date} 
     */
    constructor(timestamp) { 
        
        MonthQuery.initialize(this, timestamp);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, timestamp) { 
        obj['timestamp'] = timestamp;
    }

    /**
     * Constructs a <code>MonthQuery</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/MonthQuery} obj Optional instance to populate.
     * @return {module:model/MonthQuery} The populated <code>MonthQuery</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MonthQuery();

            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {Date} timestamp
 */
MonthQuery.prototype['timestamp'] = undefined;






export default MonthQuery;

