<template>
  <div>
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="400"
    >
      <v-card>
        <v-card-title>
          {{ locales.AddNewFixedExpenseHeader }}
        </v-card-title>
        <v-card-text>
          <v-form ref="expenseForm">
            <v-row>
              <v-col>
                <v-text-field
                    v-model="fixedExpenseName"
                    :rules="descriptionRules"
                    :label="locales.Name"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-divider></v-divider>
              <v-data-table
                  :headers="headers"
                  :items="singleExpenses"
                  :items-per-page="itemsPerPage"
                  mobile-breakpoint="0"
                  hide-default-footer>
                <template v-slot:item.value="{ item }">
                  <v-chip
                      :color="item.isExpense ? 'red' : 'green'"
                      dark
                  >
                    {{ item.value }}{{ locales.CurrencySymbol }}
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <!-- <v-btn 
                  v-if="new Date().getMonth() === new Date().getMonth()"
                  elevation="0"
                  fab
                  small
                  @click="dialogDelete = true; expenseToDelete=item">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn> -->
                </template>
              </v-data-table>
              <v-btn
                  elevation="0"
                  small
                  @click="addSingleExpenseDialog = true"
              >
                {{ locales.AddNewSingleExpense }}
              </v-btn>
              <AddNewExpenseDialog v-if="new Date().getMonth() === new Date().getMonth()"
                            :show-dialog="addSingleExpenseDialog"
                            :header-text="locales.AddNewSingleExpense"
                            @discard="addSingleExpenseDialog = false"
                            @success="singleExpenseAdded"></AddNewExpenseDialog>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="margin-left: 10px; margin-bottom: 10px">
          <v-btn
            elevation="0"
            @click="verifyForm"
          >
            {{ locales.Save }}
          </v-btn>
          <v-btn
            elevation="0"
            class="ma-1"
            @click="hideAddFixedExpenseForm()"
          >
            {{ locales.Cancel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import AddNewExpenseDialog from "@/dialogs/AddNewExpenseDialog";
import { Locales } from "@/main";
import { FixedExpenseItem } from "@/finsheet_api/src";

export default {
  name: 'EditFixedExpenseDialog',
  data() {
    return {
      locales: Locales,
      itemsPerPage: -1,
      fixedExpenseName: "",
      singleExpenses: [],
      addSingleExpenseDialog: false,
      headers: [
        {value: 'description', text: Locales.Description},
        {value: 'value', text: Locales.CurrencyValue},
        { text: '', value: 'actions', sortable: false },
      ],
      descriptionRules: [
        v => !!v || Locales.NoStringEnteredError,
      ],
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  components: {
    AddNewExpenseDialog
  },
  methods: {
    verifyForm() {
      if (this.$refs.expenseForm.validate()) {
        this.$emit('success', this.fixedExpenseName, this.singleExpenses)
        this.hideAddFixedExpenseForm()
      }
    },
    hideAddFixedExpenseForm() {
      this.fixedExpenseName = ""
      this.singleExpenses = []
      this.editFixedExpenseDialog = false
      this.$emit('discard')
    },
    singleExpenseAdded(description, value, isExpense) {
      let item = new FixedExpenseItem()
      item.description = description
      item.isExpense = isExpense
      item.value = parseFloat(value)
      this.singleExpenses.push(item)
      this.addSingleExpenseDialog = false
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
