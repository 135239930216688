<template>
  <div>
    <FixedExpensesTable v-if="selectedDate <= new Date()" :selected-date="selectedDate"></FixedExpensesTable>
  </div>
</template>

<script>

import {Locales} from "@/main";
import FixedExpensesTable from "@/components/FixedExpensesTable.vue";

export default {
  name: "FixedExpensesView",
  data() {
    return {
      locales: Locales,
      selectedDate: new Date(),
    }
  },
  mounted() {
    this.selectedDate = new Date(localStorage['selectedDate']);
    window.addEventListener('selectedDateChanged', () => {
      this.selectedDate = new Date(localStorage['selectedDate']);
    })
  },
  components: {
    FixedExpensesTable,
},
};
</script>
