<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="tableData"
        :items-per-page="itemsPerPage"
        mobile-breakpoint="0"
        hide-default-footer
    >
      <template v-slot:item.value="{ item }">
        <v-chip
            :color="getColor(item.isExpense)"
            dark
        >
          {{ item.value }}{{ locales.CurrencySymbol }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn 
        v-if="requestedDate.getMonth() === new Date().getMonth()"
        elevation="0"
        fab
        small
        @click="dialogDelete = true; expenseToDelete=item">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogDelete = false">Cancel</v-btn>
          <v-btn text @click="deleteExpense(); dialogDelete = false">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {expensesApi} from "@/backend";
import {Locales} from "@/main";
import {handleRequestError} from "@/helpers/error";
import {Expense} from "@/finsheet_api/src";

export default {
  name: 'ExpensesTable',
  components: {},
  props: {
    expenses: {
      type: Array[Expense],
      default() {
        return []
      },
    },
    categoryUuid: {
      type: String,
      default: null
    },
    requestedDate: {
      type: Date
    },
  },
  data() {
    return {
      locales: Locales,
      itemsPerPage: -1,
      headers: [
        {value: 'description', text: Locales.Description},
        {value: 'value', text: Locales.CurrencyValue},
        { text: '', value: 'actions', sortable: false },
      ],
      dialogDelete: false,
      expenseToDelete: null,
    }
  },
  watch: {},
  computed: {
    tableData() {
      return this.expenses
    },
  },
  methods: {
    getColor(isExpense) {
      if (isExpense) return 'red'
      else return 'green'
    },
    deleteExpense() {
      expensesApi.deleteExpense(this.expenseToDelete.uuid, (error, data, response) => {
        if (error) {
          handleRequestError(response)
          return
        }
        this.$emit('updateExpenseInfo')
        this.$message.success(Locales.ExpenseDeletedSuccess)
      })
    },
  }
}
</script>
