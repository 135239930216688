<template>
  <div>
    <v-card
        :loading="expenseInfo === null"
        elevation="2"
        shaped
    >
      <v-card-title>
        <div class="flex">
          <v-col>
            {{ expenseInfo.category.name }}
          </v-col>
          <v-btn
            v-if="requestedDate.getMonth() === new Date().getMonth()"
            elevation="0"
            small
            @click="addExpenseDialog = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <AddNewExpenseDialog
                         :show-dialog="addExpenseDialog"
                         :header-text="locales.AddNewExpenseTo0Header.replace('{0}', expenseInfo.category.name)"
                         @discard="addExpenseDialog = false"
                         @success="addNewExpense"></AddNewExpenseDialog>
          <UpdateCategory v-if="requestedDate.getMonth() === new Date().getMonth()"
                          @updateCategories="$emit('updateCategories')"
                          :expense-info="expenseInfo"></UpdateCategory>
          <DeleteCategory v-if="requestedDate.getMonth() === new Date().getMonth()"
                          @updateExpenseInfo="$emit('updateExpenseInfo')"
                          :expense-info="expenseInfo"></DeleteCategory>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row
            align="center"
            class="mx-0"
        >
          <v-progress-linear
              color="red darken-2"
              rounded
              height="20"
              :color="colorMethod"
              :value="percentage"
          >
            <template v-slot:default="{ value }">
              {{ calculatedExpenses.toFixed(2) }}{{ locales.CurrencySymbol }} / {{ expenseInfo.budget }}{{
                locales.CurrencySymbol
              }}
            </template>
          </v-progress-linear>
          <div class="grey--text ms-4">
            <v-icon small color="red darken-2">mdi-arrow-down</v-icon>
            <span class="text-danger">{{ expenseInfo.totalExpenses.toFixed(2) }}{{ locales.CurrencySymbol }}</span>
            &nbsp;
            <v-icon v-if="expenseInfo.totalRevenue > 0" small color="green darken-2">mdi-arrow-up</v-icon>
            <span v-if="expenseInfo.totalRevenue > 0" class="text-success">{{ expenseInfo.totalRevenue.toFixed(2) }}{{ locales.CurrencySymbol }}</span>
          </div>
        </v-row>
      </v-card-text>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <v-icon>mdi-format-list-bulleted</v-icon>
              {{ locales.Expenses }}
            </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ExpensesTable @updateExpenseInfo="$emit('updateExpenseInfo')" :expenses="expenses"
                           :category-uuid="expenseInfo.category.uuid"
                           :requested-date="requestedDate"></ExpensesTable>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import {CategoryExpenseInfo, Expense} from "@/finsheet_api/src"
import {Locales} from "@/main";
import {percentageToColorRange} from "@/helpers/colorUtil";
import UpdateCategory from "@/components/UpdateCategory";
import AddNewExpenseDialog from "@/dialogs/AddNewExpenseDialog";
import ExpensesTable from "@/components/ExpensesTable";
import DeleteCategory from "@/components/DeleteCategory";
import { expensesApi } from "@/backend";
import { handleRequestError } from "@/helpers/error";

export default {
  name: "BudgetProgress",
  components: {DeleteCategory, ExpensesTable, AddNewExpenseDialog, UpdateCategory},
  props: {
    expenseInfo: {
      type: CategoryExpenseInfo,
      default: null
    },
    expenses: {
      type: Array[Expense],
      default: []
    },
    requestedDate: {
      type: Date
    },
  },
  data() {
    return {
      locales: Locales,
      tabPosition: 'top',
      currentTab: "1",
      addExpenseDialog: false,
    }
  },
  computed: {
    percentage() {
      if (!this.expenseInfo.budget)
        return 0
      return Math.round(((this.expenseInfo.totalExpenses - this.expenseInfo.totalRevenue) / this.expenseInfo.budget) * 100)
    },
    calculatedExpenses() {
      return (this.expenseInfo.totalExpenses - this.expenseInfo.totalRevenue)
    },
    colorMethod() {
      return percentageToColorRange(this.percentage)
    },
  },
  methods: {
    addNewExpense(description, value, isExpense) {
      let expense = new Expense()
      expense.isExpense = isExpense
      expense.timestamp = new Date()
      expense.description = description
      expense.value = parseFloat(value)
      expense.categoryUuid = this.expenseInfo.category.uuid
      expensesApi.addExpense(expense, (error, data, response) => {
        if (error) {
          if (!handleRequestError(response))
          {
            let errorJson = JSON.parse(response.text)
            this.$message.error(errorJson.message)
          }
          return
        }
        this.addExpenseDialog = false
        this.$emit('updateExpenseInfo')
        this.$message.success(Locales.ExpenseAddedSuccess)
      })
    }
  },
}
</script>

<style scoped>
.flex {
  display: flex;
}
</style>