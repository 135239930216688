<template>
  <v-card class="elevation-15 spacing"
          shaped>
    <v-card-title class="text-h5">
      {{ locales.Login }}
    </v-card-title>
    <v-card-text>
      <form>
        <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            :label="locales.UserName"
            type="text"
            required
            @blur="$v.name.$touch()"
        ></v-text-field>
        <v-text-field
            v-model="password"
            :error-messages="passwordErrors"
            :label="locales.Password"
            type="password"
            required
            @blur="$v.password.$touch()"
        ></v-text-field>

        <v-btn
            class="mr-4"
            @click="submit"
        >
          {{ locales.Login }}
        </v-btn>
      </form>
      <br/>
      <span>Noch keinen Account?</span>
      <br/>
      <v-btn @click="loadRegisterPage()">{{ locales.CreateNewAccount }}</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import {setUpApis, usersApi} from "@/backend";
import router from "@/router";
import {Locales} from "@/main";

import {validationMixin} from 'vuelidate'
import {required, maxLength, minLength} from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  validations: {
    name: {required, maxLength: maxLength(10), minLength: minLength(3)},
    password: {required}
  },

  data: () => ({
    locales: Locales,
    name: '',
    password: '',
    checkbox: false,
  }),

  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push(Locales.NameRequiredError)
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      if (!this.$v.password.required) errors.push(Locales.PasswordRequiredError)
      return errors
    },
  },

  methods: {
    submit() {
      if (this.$v.$invalid && !this.$v.$dirty) {
        this.$v.$touch()
        return
      }
      this.login()
    },
    clear() {
      this.$v.$reset()
      this.name = ''
      this.password = ''
    },
    loadRegisterPage() {
      router.push('register')
    },
    login() {
      setUpApis(this.name, this.password)
      usersApi.login((error, data, response) => {
        if (error) {
          this.$message.error("Username or password is invalid");
          return
        }
        this.$message.success(Locales.LoginSuccess);
        this.loading = false;
        this.clear()
        router.push('/')
      })
    }
  },
}
// methods: {
//   async login() {
//     let valid = await this.$refs.form.validate();
//     if (!valid) {
//       return;
//     }
//     this.loading = true;
//     setUpApis(this.loginModel.username, this.loginModel.password)
//     usersApi.login((error, data, response) => {
//       if (error) {
//         this.$message.error("Username or password is invalid");
//         this.loading = false;
//         return
//       }
//       this.$message.success(Locales.LoginSuccess);
//       this.loading = false;
//       router.go(-1)
//     })
//   },
//   async register() {
//     if (this.registerModel.password !== this.registerModel.repeatedPassword)
//     {
//       this.$message.error(Locales.PasswordsMustMatchError)
//       this.registerModel.password = ""
//       this.registerModel.repeatedPassword = ""
//       return
//     }
//     let valid = await this.$refs.form.validate();
//     if (!valid) {
//       return;
//     }
//     this.loading = true;
//     let registerQuery = new RegisterUserQuery(this.registerModel.username, this.registerModel.password)
//     registerQuery.userEmail = ""  // TODO: for now
//     usersApi.register(registerQuery, (error, data, response) => {
//       if (error) {
//         handleRequestError(response)
//         let errorJson = JSON.parse(response.text)
//         this.$message.error(errorJson.message)
//         this.loading = false;
//         return
//       }
//       this.$message.success(Locales.RegistrationSuccess);
//       this.registerModel.password = ""
//       this.registerModel.repeatedPassword = ""
//       this.registerModel.username = ""
//       this.loading = false;
//     })
//   },
// }

</script>
