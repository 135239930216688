/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Category from './Category';

/**
 * The CategoryExpenseInfo model module.
 * @module model/CategoryExpenseInfo
 * @version 0.0.5
 */
class CategoryExpenseInfo {
    /**
     * Constructs a new <code>CategoryExpenseInfo</code>.
     * @alias module:model/CategoryExpenseInfo
     */
    constructor() { 
        
        CategoryExpenseInfo.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CategoryExpenseInfo</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CategoryExpenseInfo} obj Optional instance to populate.
     * @return {module:model/CategoryExpenseInfo} The populated <code>CategoryExpenseInfo</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CategoryExpenseInfo();

            if (data.hasOwnProperty('category')) {
                obj['category'] = Category.constructFromObject(data['category']);
            }
            if (data.hasOwnProperty('totalExpenses')) {
                obj['totalExpenses'] = ApiClient.convertToType(data['totalExpenses'], 'Number');
            }
            if (data.hasOwnProperty('totalRevenue')) {
                obj['totalRevenue'] = ApiClient.convertToType(data['totalRevenue'], 'Number');
            }
            if (data.hasOwnProperty('budget')) {
                obj['budget'] = ApiClient.convertToType(data['budget'], 'Number');
            }
            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/Category} category
 */
CategoryExpenseInfo.prototype['category'] = undefined;

/**
 * @member {Number} totalExpenses
 */
CategoryExpenseInfo.prototype['totalExpenses'] = undefined;

/**
 * @member {Number} totalRevenue
 */
CategoryExpenseInfo.prototype['totalRevenue'] = undefined;

/**
 * @member {Number} budget
 */
CategoryExpenseInfo.prototype['budget'] = undefined;

/**
 * @member {Date} date
 */
CategoryExpenseInfo.prototype['date'] = undefined;






export default CategoryExpenseInfo;

