/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The BaseError model module.
 * @module model/BaseError
 * @version 0.0.5
 */
class BaseError {
    /**
     * Constructs a new <code>BaseError</code>.
     * all information an Error message has
     * @alias module:model/BaseError
     * @param tag {String} 
     * @param message {String} 
     * @param timestamp {Date} 
     * @param source {String} 
     * @param code {Number} 
     * @param trace {String} 
     */
    constructor(tag, message, timestamp, source, code, trace) { 
        
        BaseError.initialize(this, tag, message, timestamp, source, code, trace);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, tag, message, timestamp, source, code, trace) { 
        obj['tag'] = tag;
        obj['message'] = message;
        obj['timestamp'] = timestamp;
        obj['source'] = source;
        obj['code'] = code;
        obj['trace'] = trace;
    }

    /**
     * Constructs a <code>BaseError</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BaseError} obj Optional instance to populate.
     * @return {module:model/BaseError} The populated <code>BaseError</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BaseError();

            if (data.hasOwnProperty('tag')) {
                obj['tag'] = ApiClient.convertToType(data['tag'], 'String');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = ApiClient.convertToType(data['message'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('source')) {
                obj['source'] = ApiClient.convertToType(data['source'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'Number');
            }
            if (data.hasOwnProperty('trace')) {
                obj['trace'] = ApiClient.convertToType(data['trace'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} tag
 */
BaseError.prototype['tag'] = undefined;

/**
 * @member {String} message
 */
BaseError.prototype['message'] = undefined;

/**
 * @member {Date} timestamp
 */
BaseError.prototype['timestamp'] = undefined;

/**
 * @member {String} source
 */
BaseError.prototype['source'] = undefined;

/**
 * @member {Number} code
 */
BaseError.prototype['code'] = undefined;

/**
 * @member {String} trace
 */
BaseError.prototype['trace'] = undefined;






export default BaseError;

