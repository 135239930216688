/**
 * FinSheet-API
 * REST API for FinSheet Application
 *
 * The version of the OpenAPI document: 0.0.5
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BaseError from '../model/BaseError';
import CategoryExpenseInfo from '../model/CategoryExpenseInfo';
import Expense from '../model/Expense';
import MonthQuery from '../model/MonthQuery';

/**
* Expenses service.
* @module api/ExpensesApi
* @version 0.0.5
*/
export default class ExpensesApi {

    /**
    * Constructs a new ExpensesApi. 
    * @alias module:api/ExpensesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addExpense operation.
     * @callback module:api/ExpensesApi~addExpenseCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Adds a new entry to the expenses table of the logged in user
     * @param {module:model/Expense} expense 
     * @param {module:api/ExpensesApi~addExpenseCallback} callback The callback function, accepting three arguments: error, data, response
     */
    addExpense(expense, callback) {
      let postBody = expense;
      // verify the required parameter 'expense' is set
      if (expense === undefined || expense === null) {
        throw new Error("Missing the required parameter 'expense' when calling addExpense");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/expenses/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteExpense operation.
     * @callback module:api/ExpensesApi~deleteExpenseCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Deletes an expense from the current user
     * @param {String} expenseUuid UUID of the expense to delete
     * @param {module:api/ExpensesApi~deleteExpenseCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteExpense(expenseUuid, callback) {
      let postBody = null;
      // verify the required parameter 'expenseUuid' is set
      if (expenseUuid === undefined || expenseUuid === null) {
        throw new Error("Missing the required parameter 'expenseUuid' when calling deleteExpense");
      }

      let pathParams = {
        'expense_uuid': expenseUuid
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = null;
      return this.apiClient.callApi(
        '/expenses/delete/{expense_uuid}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCategoryExpensesInfo operation.
     * @callback module:api/ExpensesApi~getCategoryExpensesInfoCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/CategoryExpenseInfo>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Returns a list of expenses so far for each category in a given time interval
     * @param {module:model/MonthQuery} monthQuery 
     * @param {module:api/ExpensesApi~getCategoryExpensesInfoCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/CategoryExpenseInfo>}
     */
    getCategoryExpensesInfo(monthQuery, callback) {
      let postBody = monthQuery;
      // verify the required parameter 'monthQuery' is set
      if (monthQuery === undefined || monthQuery === null) {
        throw new Error("Missing the required parameter 'monthQuery' when calling getCategoryExpensesInfo");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [CategoryExpenseInfo];
      return this.apiClient.callApi(
        '/expenses/getInfoPerCategory', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getExpensesForMonth operation.
     * @callback module:api/ExpensesApi~getExpensesForMonthCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/Expense>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * returns a list of all expenses so far of the logged in user for the specified month
     * @param {module:model/MonthQuery} monthQuery 
     * @param {module:api/ExpensesApi~getExpensesForMonthCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/Expense>}
     */
    getExpensesForMonth(monthQuery, callback) {
      let postBody = monthQuery;
      // verify the required parameter 'monthQuery' is set
      if (monthQuery === undefined || monthQuery === null) {
        throw new Error("Missing the required parameter 'monthQuery' when calling getExpensesForMonth");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = [Expense];
      return this.apiClient.callApi(
        '/expenses/getPerMonth', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
