<template>
  <div id="app">
    <Login></Login>
  </div>
</template>

<script>

import Login from "@/components/Login";

export default {
  name: "LoginView",
  components: {
    Login
  }
};
</script>

<style lang="scss">


</style>
